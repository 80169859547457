body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Arial', sans-serif;
}

.App {
  text-align: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.App-header {
  background-color: white;
  padding: 20px;
  position: relative;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.App-logo {
  height: 60px;
  object-fit: contain;
}

.content-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 80%;
  max-width: 800px;
}

main.glass-effect {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.15));
  backdrop-filter: blur(15px);  
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.1);  
}
h1 {
  color: #333;
  margin-bottom: 20px;
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.5);  
}

.Mission-statement {
  font-size: 1.1rem;
  margin: 0 auto 30px;
  line-height: 1.8;  
  color: #1a1a1a;  
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.7);  
  font-weight: 500;  
  max-width: 90%;  
}

/* Add a subtle text stroke for extra contrast */
@supports (-webkit-text-stroke: 1px rgba(255,255,255,0.1)) {
  .Mission-statement {
    -webkit-text-stroke: 1px rgba(255,255,255,0.1);
  }
}

/* Responsive font size for smaller screens */
@media (max-width: 768px) {
  .Mission-statement {
    font-size: 1rem;
  }
}

.Cta-button {
  background-color: #5F64FA;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(95, 100, 250, 0.3);
  display: inline-block;
}

.Cta-button:hover {
  background-color: #4a4fd8;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(95, 100, 250, 0.5);
}

footer {
  background-color: rgba(241, 243, 245, 0.3);  
  padding: 10px;
  font-size: 0.9rem;
  color: #333; 
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  backdrop-filter: blur(5px);  
}

/* Spline Viewer Styles */
.spline-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

spline-viewer {
  width: 100%;
  height: 100%;
}

.text-overlay {
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 10px;
  backdrop-filter: blur(5px);
}